<template>
  <div :class="walletOptions ? 'w-full' : 'flex flex-col items-center w-full'">
    <div
      class="flex animate-slide-down-fade-in04s mb-5 mt-5"
      :class="{ 'flex-col': walletOptions, 'flex-col w-full space-y-2': vertical }"
    >
      <ButtonButton
        v-for="(data, key) in webData"
        :key="key"
        :padding="false"
        :is-loading="data?.loading"
        :disabled="isLoading"
        :class="[
          buttonClass,
          'h-[50px] my-auto'
        ]"
        theme="twitter"
        size="sm"
        @click.prevent="emits('on-login', data?.label)"
      >
        <template v-if="!vertical">
          <span v-if="walletOptions" class="pl-20 text-base">
            {{ data?.label }}
          </span>
          <div :class="imageClass">
            <SvgIcon
              :icon="data?.icon"
              class="inline-block relative"
              width="30"
              height="30"
            />
            <SvgIcon
              class="absolute -left-1 -top-1"
              :icon="data?.chain"
              width="14"
              height="14"
            />
          </div>
        </template>
        <div v-else class="flex flex-row justify-start items-center">
          <div :class="imageClass">
            <SvgIcon
              :icon="data?.icon"
              class="inline-block relative"
              width="30"
              height="30"
            />
          </div>
          <div class="text-sm">
            {{ data?.label }}
          </div>
        </div>
      </ButtonButton>
    </div>
    <ButtonButton
      v-if="!walletOptions && !hideConnect"
      class="w-full mb-2"
      theme="primary"
      size="sm"
      :disabled="isLoading"
      @click.prevent.stop="showWalletOptions"
    >
      Connect
    </ButtonButton>
    <div
      v-if="!web3Only"
      :class="walletOptions ? 'pt-0 pb-5' : 'py-3'"
      class="flex justify-center w-full items-center text-xs"
    >
      <span
        class="relative w-full text-center text-slate-light font-bold before:content-[''] before:w-[calc(50%-16px)] before:h-px before:left-0 before:top-1/2 before:absolute before:bg-[#5F677C] after:content-[''] after:w-[calc(50%-16px)] after:h-px after:right-0 after:top-1/2 after:absolute after:bg-[#5F677C]"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  webData: {
    type: Object,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  web3Only: {
    type: Boolean,
    default: false,
  },
  hideConnect: {
    type: Boolean,
    default: false,
  },
  vertical: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['on-login',]);

const walletOptions = ref(false);

const buttonClass = computed(() => {
  if (walletOptions.value) {
    return 'w-full rounded-[14px] normal-case bg-white/10 border-white/10 hover:bg-slate-500/75 mb-2 text-start text-ellipsis overflow-hidden disabled:hover:bg-slate-500/50 focus:bg-slate-500/75 active:bg-slate-500/75 py-3 border-slate-100/25 border-2';
  } else if (props.vertical) {
    return 'rounded-2xl px-4 bg-white/10 border-2 border-white/10 transition-all hover:bg-[#4b4240] hover:scale-105 disabled:hover:bg-slate-500/50';
  } else {
    return 'rounded-2xl p-0 mr-2 bg-white/10 border-2 border-white/10 transition-all hover:bg-[#4b4240] hover:scale-125 disabled:hover:bg-slate-500/50';
  }
});

const imageClass = computed(() => {
  return walletOptions.value
    ? 'flex items-center justify-center ml-1 w-9 h-9 absolute top-1/2 -mt-[18px] left-4'
    : 'flex items-center justify-center p-2';
});

function showWalletOptions() {
  walletOptions.value = true;
}
</script>
